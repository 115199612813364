.watches {
  min-height: 400px;
  &-list {
    height: auto;

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    gap: 40px;
    padding: 25px 10px;

    &-item {
      cursor: pointer;
      width: 232px;
      display: flex;
      flex-direction: column;
      transition: 250ms;

      &:hover {
        box-shadow: 1px 1px 10px 1px #c59d5f;
      }

      &-image {
        width: 232px;
        height: 232px;
        background-size: cover;
        position: relative;
        color: white;
        text-transform: capitalize;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        &-gender {
          width: 100%;
          padding-top: 10px;
          position: absolute;
          left: 0;
          top: 0;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        &-isAvailable {
          padding: 8px;
          position: absolute;
          left: 0;
          bottom: 0;
        }
      }

      &-description {
        width: 100%;
        height: auto;
        // padding: 8px;
        padding-top: 8px;
        padding-left: 8px;
        display: flex;
        // flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        text-transform: capitalize;
        gap: 13px;
        color: black;
        &-type {
          font-size: 13px;
          color: var(--grey);
        }
        &-title {
          font-size: 15px;
          font-weight: 500;
        }
        &-color {
          color: grey;
        }

        > p {
          margin-bottom: 10px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &-buy {
          padding: 10px 10px 5px 10px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-top: solid 1px rgb(206, 206, 206);

          &-btn {
            width: 80px;
            font-size: 13px;
            border: 1px solid var(--black);
            outline: none;
            color: var(--black);
            padding: 8px 5px;
            cursor: pointer;
            background-color: var(--white);
            border-radius: 10px;
            transition: all 0.3s linear;
            text-decoration: none;
            &:hover {
              background-color: var(--grey);
            }
          }

          &-price {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 2px;
            font-size: 16px;
            font-weight: 500;
            color: var(--black);
            > svg {
              width: 17px;
              height: 17px;
            }
            > del {
              font-size: 12px;
              > svg {
                width: 13px;
                height: 13px;
              }
            }
            > span {
              display: flex;
              align-items: center;
            }
          }
        }
      }
    }

    @media only screen and (max-width: 690px) {
      gap: 0;
      padding: 0 15px;
      justify-content: space-between;
      &-item {
        width: 221px;
        margin-top: 10px;

        &-image {
          width: 100%;
          height: 221px;

          > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }

    @media only screen and (max-width: 560px) {
      &-item {
        width: 48%;
        &-image {
          width: 100%;
          height: 182px;
          &-isAvailable {
            padding: 3px;
            font-size: 10px;
          }
        }
        &-description {
          &-title {
            font-size: 12px;
          }
          &-type {
            font-size: 11px;
          }
          &-buy {
            gap: 10px;
            flex-wrap: wrap;
            justify-content: space-between;
            &-price {
              font-size: 13px;
              flex-wrap: wrap;
              flex-direction: column;
              > del {
                font-size: 10px;
                display: flex;
                > svg {
                  width: 11px;
                  height: 11px;
                }
              }
              > svg {
                width: 15px;
                height: 15px;
              }
            }
            &-btn {
              width: 65px;
              padding: 8px 0;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 10px;
            }
          }
        }
      }
    }

    @media only screen and (max-width: 400px) {
      &-item {
        &-description {
          &-buy {
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            padding: 10px;
            &-price {
              display: flex;
              flex-direction: column;
              > span {
                font-size: 12px;
              }
              > del {
                font-size: 10px;
              }
            }

            > button {
              width: 50px;
            }
          }
        }
      }
    }
  }
}
