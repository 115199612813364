.header-inner {
  width: 100%;
  height: 100px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--grey);

  &-dots {
    width: 24px;
    height: 24px;
    margin-left: 10px;
    cursor: pointer;
    position: relative;

    &-icon {
      width: 24px;
      height: 24px;
    }

    &-dialog {
      width: 150px;
      height: auto;
      position: absolute;
      right: -250%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      background-color: var(--white);
      border: 1px solid var(--grey);
      transition: all 0.5s linear;

      &-item {
        padding: 8px;
        text-align: center;
        color: var(--black);
        text-decoration: none;

        &:hover {
          background-color: var(--grey);
        }

        &:first-child {
          border-bottom: 1px solid var(--grey);
        }
      }
    }
  }

  //
  &-menu-icon {
    display: none;
    cursor: pointer;
  }

  //

  &-nav {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2px;

    &:hover > :not(:hover) {
      opacity: 0.4;
    }

    &-link {
      font-size: 14px;
      font-weight: 500;
      text-decoration: none;
      color: var(--black);
      padding: 10px 15px;
      border-radius: 10px;
      transition: 0.3s;
    }
  }

  &-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 100px;

    &-img {
      width: 100%;
      height: 100%;
      fill: #fff;
    }
  }

  &-mobile-logo {
    display: none;
  }

  &-language {
    width: 80px;
    height: auto;
    position: relative;
    cursor: pointer;

    &-lang {
      font-size: 16px;
      display: flex;
      align-items: center;
      gap: 2px;
      color: var(--black);

      &-icon {
        width: 18px;
        height: 18px;
        margin-top: 2px;
        transition: transform 0.1s linear;
      }
    }

    &-dialog {
      width: 100px;
      height: 70px;
      position: absolute;
      top: 30px;
      right: -12px;
      display: flex;
      flex-direction: column;
      gap: 5px;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      padding: 4px;
      background-color: var(--white);
      border: 3px solid var(--grey);

      &-item {
        width: 100%;
        font-size: 15px;
        font-weight: 500;
        color: var(--black);
        text-align: center;
        padding: 2px;
        transition: background 0.2s linear;

        &:hover {
          background-color: var(--grey);
        }
      }
    }
  }

  @media (max-width: 1231px) {
    .header-inner {
      &-nav {
        &-link {
          font-size: 13px;
        }
      }

      &-logo {
        width: 140px;
        height: 90px;
      }

      &-language {
        &-lang {
          font-size: 15px;
        }
      }
    }
  }

  @media only screen and (max-width: 1008px) {
    &-menu-icon {
      display: block;
      font-size: 40px;
    }

    &-nav {
      display: none;
    }
    .header-inner {
      padding: 0 85px;
    }
  }

  @media only screen and (max-width: 530px) {
    &-logo {
      display: none;
    }

    &-mobile-logo {
      display: block;
      margin-right: 90px;
    }

    &-language {
      width: 60px;
      margin-right: 15px;

      &-lang {
        font-size: 13px;
      }

      &-dialog {
        right: -20px;
        top: 34px;
      }
    }
  }
}

@media only screen and (max-width: 450px) {
  .header-inner {
    &-mobile-logo {
      margin-right: 45px;
    }
  }
}
