.filter {
  width: 100%;
  &-inner {
    width: 100%;
    min-height: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 25px;
    // position: relative;
    padding: 0 15px;
    &-select {
      display: flex;
      align-items: center;
      width: max-content;
      color: var(--black);
      padding: 8px;
      cursor: pointer;
      border: 1px solid var(--grey);
      transition: 0.25s;
      position: relative;
      &:hover {
        box-shadow: 6px 6px 10px black;
      }
      > svg {
        width: 24px;
        height: 24px;
        margin-right: 8px;
      }
      > span {
        font-size: 16px;
        font-weight: bold;
      }
    }
    &-options {
      width: max-content;
      background-color: var(--white);
      padding: 20px 26px;
      padding-bottom: 16px;
      border: 3px solid var(--grey);
      border-radius: 5px;
      margin-top: 16px;
      display: flex;
      flex-direction: column;
      gap: 16px;
      position: absolute;
      top: 100%;
      right: -20%;
      z-index: 999;
      @media only screen and (max-width: 400px) {
        right: -17%;
        padding: 18px 12px;
      }
      &-triangle {
        width: 36px;
        height: 36px;
        position: absolute;
        top: -22%;
        left: 40%;
        // top: -16%;
        // left: 100px;
      }
      &-gender {
        display: flex;
        gap: 16px;
        &-item {
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          border: 1px solid var(--black);
          padding: 6px 12px;
          border-radius: 16px;
          font-weight: 500;
        }
      }
      &-types {
        display: flex;
        gap: 16px;
        &-option {
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          border: 1px solid var(--black);
          padding: 6px 12px;
          border-radius: 16px;
          font-weight: 500;
        }
      }
      > button {
        background-color: var(--white);
        color: var(--black);
        border: 2px solid var(--grey);
        border-radius: 7px;
        width: 40%;
        padding: 6px 12px;
        font-weight: 500;
        font-size: 16px;
        cursor: pointer;
        align-self: flex-end;
        transition: background 0.3s linear;
        &:hover {
          background-color: var(--grey);
        }
      }
    }
    &-beltOptions {
      width: max-content;
      background-color: var(--white);
      padding: 20px 26px;
      padding-bottom: 16px;
      border: 3px solid var(--grey);
      border-radius: 5px;
      margin-top: 16px;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      gap: 16px;
      position: absolute;
      top: 100%;
      right: -110%;
      z-index: 999;
      @media only screen and (max-width: 881px) {
        right: -70%;
        padding: 18px 12px;
      }
      @media only screen and (max-width: 710px) {
        right: -50%;
        padding: 18px 12px;
      }
      @media only screen and (max-width: 582px) {
        right: -60%;
        padding: 18px 12px;
      }
      @media only screen and (max-width: 480px) {
        right: 0;
        padding: 18px 12px;
        &-belts {
          flex-direction: column;
        }
      }
      &-belts {
        display: flex;
        gap: 16px;
        &-item {
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          border: 1px solid var(--black);
          padding: 6px 12px;
          border-radius: 16px;
          font-weight: 500;
        }
      }
      &-beltTriangle {
        width: 36px;
        height: 36px;
        position: absolute;
        top: -37%;
        left: 37%;
        @media only screen and (max-width: 480px) {
          top: -15%;
        }
      }
      > button {
        background-color: var(--white);
        color: var(--black);
        border: 2px solid var(--grey);
        border-radius: 7px;
        width: 40%;
        padding: 6px 12px;
        font-weight: 500;
        font-size: 16px;
        cursor: pointer;
        align-self: flex-end;
        transition: background 0.3s linear;
        &:hover {
          background-color: var(--grey);
        }
      }
    }
    &-rangeSlider {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      &-value {
        font-size: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 2px;
        width: 100px;
        height: 100%;
      }
    }

    &-selection {
      position: relative;
      width: 32%;
      gap: 2px;
      &-select {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 2px;
        padding: 10px;
        > div {
          font-size: 18px;
          font-weight: 400;
        }
      }
      &-options {
        width: max-content;
        height: auto;
        background-color: var(--white);
        position: absolute;
        z-index: 4;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border: none;
        transition: all 0.3s linear;

        &-option {
          background-color: var(--white);
          width: 100%;
          font-size: 15px;
          padding: 7px 50px;
          border: 1px solid var(--grey);
          text-align: center;
          cursor: pointer;
          transition: background 0.2s linear;
          &:hover {
            background-color: var(--grey);
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 881px) {
  .filter {
    &-inner {
      margin-top: 20px;
      flex-direction: column;
      &-selection {
        // &-options {
        //   right: -30%;
        // }
      }
    }
  }
}

@media (max-width: 790px) {
  .filter {
    &-inner {
      flex-direction: column;
      padding: 25px 0px;
      &-selection {
        width: 40%;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .filter {
    &-inner {
      &-types {
        flex-wrap: wrap;
      }
      &-selection {
        width: 60%;
        &-options {
          left: 5%;
        }
      }
    }
  }
}

@media only screen and (max-width: 431px) {
  .filter {
    &-inner {
      &-selection {
        width: 60%;
        &-options {
          left: 0%;
        }
      }
    }
  }
}
