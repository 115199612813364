.belts {
  &-list {
    min-height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 40px;
    padding: 20px 10px;
    &-item {
      width: 232px;
      cursor: pointer;
      transition: 250ms;

      &:hover {
        box-shadow: 1px 1px 10px 1px #c59d5f;
      }

      &-image {
        width: 100%;
        height: 232px;
        position: relative;
        color: white;
        text-transform: capitalize;
        > img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        &-isAvailable {
          padding: 8px;
          position: absolute;
          left: 0;
          bottom: 0;
          @media only screen and (max-width: 560px) {
            padding: 3px;
            font-size: 10px;
          }
        }
      }

      &-description {
        padding: 8px 0 0 6px;
        &-title {
          display: flex;
          justify-content: space-between;
          margin-bottom: 8px;
          padding: 0 5px;
          > div {
            font-size: 13px;
            font-weight: 600;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          > p {
            font-size: 12px;
          }
        }
        &-color {
          color: grey;
        }

        &-buy {
          width: 100%;
          display: flex;
          margin-top: 10px;
          padding: 10px 5px;
          align-items: center;
          justify-content: space-between;
          border-top: 1px solid rgb(206, 206, 206);

          &-btn {
            border: 1px solid var(--black);
            outline: none;
            color: var(--black);
            padding: 8px 20px;
            cursor: pointer;
            background-color: var(--white);
            border-radius: 10px;
            transition: all 0.3s linear;

            &:hover {
              background-color: var(--grey);
            }
          }

          &-price {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 2px;
            font-size: 18px;
            font-weight: 500;
            color: var(--black);
          }
        }
      }
    }

    @media only screen and (max-width: 658px) {
      &-item {
        width: 30%;
        // min-height: 313px;
        &-image {
          width: 100%;
          height: 182px;
        }
        &-description {
          &-title {
            font-size: 12px;
          }
          &-type {
            font-size: 11px;
          }
          &-buy {
            gap: 20px;
            flex-wrap: wrap;
            justify-content: space-between;
            padding-left: 5px;
            padding-right: 5px;
            &-price {
              font-size: 13px;
              flex-wrap: wrap;
              > del {
                font-size: 10px;
                display: flex;
                > svg {
                  width: 11px;
                  height: 11px;
                }
              }
              > svg {
                width: 15px;
                height: 15px;
              }
            }
            &-btn {
              width: 65px;
              padding: 8px 0;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 12px;
            }
          }
        }
      }
    }

    @media only screen and (max-width: 570px) {
      &-item {
        width: 40%;
        // min-height: 313px;
        &-image {
          width: 100%;
          height: 182px;
        }
        &-description {
          &-title {
            font-size: 12px;
          }
          &-type {
            font-size: 11px;
          }
          &-buy {
            gap: 10px;
            flex-wrap: wrap;
            justify-content: space-between;
            &-price {
              font-size: 13px;
              flex-wrap: wrap;
              display: flex;
              flex-direction: column;
              > del {
                font-size: 10px;
                display: flex;
                > svg {
                  width: 11px;
                  height: 11px;
                }
              }

              > span {
                display: flex;
                justify-content: center;
                align-items: center;
                > svg {
                  width: 15px;
                  height: 15px;
                }
              }
            }
            &-btn {
              width: 65px;
              padding: 8px 0;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 12px;
            }
          }
        }
      }
    }
    @media only screen and (max-width: 460px) {
      &-item {
        width: 45%;
      }
    }
  }

  @media only screen and (max-width: 692px) {
    &-list {
      gap: 12px;
    }
  }

  // @media only screen and (max-width: 650px) {
  //   &-list {
  //     gap: 0;
  //     padding: 0 20px;
  //     justify-content: space-between;
  //     &-item {
  //       width: 48%;

  //       &-description {
  //         &-buy {
  //           flex-direction: column;
  //           gap: 16px;
  //           &-price {
  //             margin-left: 10px;
  //           }

  //           &-btn {
  //             margin-top: 10px;
  //           }
  //         }
  //       }
  //     }
  //   }
  // }
}
