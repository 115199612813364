.about-us {
  &-desc {
    width: 100%;
    min-height: 443px; //
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    color: black;
    padding: 24px 0;
    background-size: cover;

    &-title {
      font-size: 28px;
      text-align: center;
    }

    &-txt {
      max-width: 900px;
      margin-top: 20px;
      text-align: center;
      transition: 0.5s;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: black;
    }
  }

  @media only screen and (max-width: 1110px) {
    &-desc {
      padding: 24px 14px;
    }
  }
}
