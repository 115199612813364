.checkout {
  width: 100%;
  height: 100%;
  &-inner {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
    &-title {
      font-size: 26px;
      font-weight: normal;
      line-height: 120%;
      margin: 40px 0;
    }
  }
}
