.footer-inner {
  max-width: var(--global-max--width);
  display: flex;
  min-height: 200px;
  align-items: center;
  justify-content: space-between;
  margin: auto;
  color: var(--white);
  padding: 10px 50px;

  &-info {
    display: flex;
    align-items: center;
    justify-content: center;

    &-logo {
      font-family: "Kaushan Script", cursive;
      font-weight: 500;
      font-size: 32px;
    }
  }

  &-contact {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 15px;

    &-title {
      font-weight: 500;
      font-size: 24px;
    }

    &-info {
      display: flex;
      flex-direction: column;
      gap: 10px;

      &-item {
        display: flex;
        align-items: center;
        gap: 5px;

        &-icon {
          width: 22px;
          height: 22px;
        }
      }
    }
  }

  &-follow {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;

    &-title {
      font-weight: 500;
      font-size: 24px;
    }

    &-links {
      display: flex;
      align-items: center;
      gap: 10px;

      > a {
        color: var(--white);
      }

      &-link {
        cursor: pointer;
        width: 22px;
        height: 22px;
        transition: fill 0.3s linear;

        &:hover {
          fill: grey;
        }
      }
    }
  }

  @media only screen and (max-width: 870px) {
    flex-direction: column;
    align-items: center;
    padding: 20px;
    gap: 40px;

    &-info {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-contact {
      width: 100%;
      align-items: center;
      &-title {
        font-size: 20px;
      }
    }

    &-follow {
      width: 100%;
      align-items: center;
      &-title {
        font-size: 20px;
      }
    }
  }
}

.foot {
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  background-color: rgb(17, 16, 16);
  color: var(--white);
}
