.checkoutItems {
  width: 100%;
  height: auto;
  padding: 30px 20px;
  border: 2px solid var(--grey);
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  &-title {
    font-size: 20px;
    text-align: center;
  }
  &-items {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 15px;
    &-item {
      width: 100%;
      height: auto;
      display: flex;
      align-items: center;
      padding: 10px 20px 10px 0;
      gap: 20px;
      position: relative;
      background-color: var(--white);
      border-bottom: 1px solid var(--grey);
      border-top: 1px solid var(--grey);
      &-image {
        width: 130px;
        height: 130px;
        &-img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 10px;
        }
      }
      &-info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 15px;
        padding: 10px 0;
        &-name {
          font-size: 17px;
          font-weight: 500;
          color: var(--black);
        }
        &-price {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 3px;
          > span {
            font-size: 15px;
          }
        }
        &-view {
          color: var(--black);
        }
      }

      &-remove {
        width: 24px;
        height: 24px;
        position: absolute;
        right: 5%;
        cursor: pointer;
        &-icon {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  &-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-total {
      font-size: 18px;
      width: 100%;
      height: auto;
      display: flex;
      align-items: center;
      gap: 10px;
      > span {
        font-size: 20px;
        font-weight: 500;
        color: var(--black);
      }
    }

    &-confirm {
      font-size: 15px;
      padding: 10px 17px;
      border: 2px solid var(--grey);
      border-radius: 7px;
      cursor: pointer;
      color: var(--black);
      text-transform: uppercase;
      transition: all 0.3s linear;
      &:hover {
        background-color: var(--grey);
        border: 2px solid var(--white);
        opacity: 0.8;
      }
    }
  }

  &-none {
    text-align: center;
    color: var(--black);
    opacity: 0.8;
    cursor: pointer;
    text-decoration: none;
    &:hover {
      opacity: 1;
    }
  }
}

@media only screen and (max-width: 500px) {
  .checkoutItems {
    &-footer {
      &-total {
        font-size: 14px;
        > span {
          font-size: 14px;
          > svg {
            width: 13px;
            height: 13px;
          }
        }
      }

      &-confirm {
        width: 47%;
        font-size: 14px;
        padding: 3px 5px;
      }
    }
  }
}
