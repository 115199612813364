.error {
  width: 100%;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url("../../images/error-bg.jpg") no-repeat;
  background-size: cover;

  &-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &-number {
      text-shadow: 0 0 3px var(--grey);
      > h1 {
        font-size: 100px;
        font-weight: 900;
        background-image: linear-gradient(to right, var(--black), black);
        background-clip: text;
        color: transparent;
      }
    }

    &-text {
      font-size: 20px;
      color: var(--black);
      opacity: 0.8;
    }
  }
}
