.request {
  width: 100%;
  height: 90vh;
  background: url("../../../images/requestErr-bg.jpg") no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  &-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &-title {
      text-shadow: 0 0 3px var(--grey);
      > h1 {
        font-size: 85px;
        font-weight: 700;
        text-transform: uppercase;
        background-image: linear-gradient(to right, var(--black), black);
        background-clip: text;
        color: transparent;
      }
    }
    &-text {
      font-size: 40px;
      font-weight: 400;
      opacity: 0.8;
    }
  }
}
