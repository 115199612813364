.information {
  width: 100%;
  height: auto;
  //   background-color: red;
  padding: 30px 20px;
  border: 2px solid var(--grey);
  &-title {
    margin-bottom: 20px;
    text-align: center;
    color: var(--black);
    font-size: 20px;
    color: var(--black);
  }
  &-payer {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 0 10px;
    &-name {
      font-size: 17px;
      margin-bottom: 10px;
    }

    &-form {
      display: flex;
      align-items: center;
      gap: 25px;
      &-item {
        display: flex;
        align-items: center;
        gap: 10px;
        > label {
          font-size: 15px;
          font-weight: 500;
          color: var(--black);
        }

        > input {
          width: 200px;
          height: 25px;
          background-color: var(--white);
          border: 1px solid var(--grey);
          &:focus {
            outline: none;
          }
        }
      }
    }
  }

  &-shipping {
    margin-top: 15px;
    &-delText {
      font-size: 13px;
      width: 100%;
      text-align: center;
      // padding-top: 22px;
      // padding-bottom: 10px;
      margin-bottom: 20px;
      > a {
        color: var(--black);
        font-weight: 700;
      }
    }
    &-title {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-bottom: 10px;
      &-name {
        font-size: 17px;
        border-right: 1px solid var(--black);
        padding: 0 10px;
      }

      &-cityName {
        display: flex;
        align-items: center;
        gap: 15px;
        &-check {
          display: flex;
          align-items: center;
          gap: 15px;
          position: relative;
          margin: 20px auto;
          > label {
            width: 17px;
            height: 17px;
            cursor: pointer;
            position: absolute;
            top: 0;
            left: 0;
            background: #fcfff4;
            background: linear-gradient(
              to top,
              #fcfff4 0%,
              #dfe5d7 40%,
              var(--grey) 100%
            );
            border-radius: 4px;
            box-shadow: inset 0px 1px 1px white, 0px 1px 3px rgba(0, 0, 0, 0.5);
            &:after {
              content: "";
              width: 9px;
              height: 5px;
              position: absolute;
              top: 6px;
              left: 4px;
              border: 3px solid #333;
              border-top: none;
              border-right: none;
              background: transparent;
              opacity: 0;
              transform: rotate(-45deg);
            }
            &:hover::after {
              opacity: 0.5;
            }
          }

          > input[type="checkbox"] {
            visibility: hidden;
            &:checked + label:after {
              opacity: 1;
            }
          }

          > div {
            font-size: 15px;
          }
        }
      }
    }

    &-notes {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      gap: 10px;
      padding: 0 10px;
      margin-top: 20px;
      > label {
        font-size: 15px;
        font-weight: 500;
        color: var(--black);
      }

      > input {
        width: 100%;
        height: 30px;
        background-color: var(--white);
        border: 1px solid var(--grey);
        &:focus {
          outline: none;
        }
      }
    }
  }
  &-formForErevan {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 0 10px;
    &-address {
      display: flex;
      align-items: center;
      gap: 10px;
      > label {
        font-size: 15px;
        font-weight: 500;
        color: var(--black);
      }

      > input {
        width: 200px;
        height: 25px;
        background-color: var(--white);
        border: 1px solid var(--grey);
        &:focus {
          outline: none;
        }
      }
    }
    &-time {
      display: flex;
      align-items: center;
      > div {
        font-size: 15px;
        font-weight: 500;
        color: var(--black);
        border-right: 1px solid var(--grey);
        padding: 0 10px;
        margin-right: 10px;
      }
      &-hours {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        position: relative;
        > label {
          width: 15px;
          height: 15px;
          cursor: pointer;
          position: absolute;
          top: 0;
          left: 0;
          background: #fcfff4;
          background: linear-gradient(
            to top,
            #fcfff4 0%,
            #dfe5d7 40%,
            var(--grey) 100%
          );
          border-radius: 4px;
          box-shadow: inset 0px 1px 1px white, 0px 1px 3px rgba(0, 0, 0, 0.5);
          &:after {
            content: "";
            width: 9px;
            height: 5px;
            position: absolute;
            top: 5px;
            left: 4px;
            border: 3px solid #333;
            border-top: none;
            border-right: none;
            background: transparent;
            opacity: 0;
            transform: rotate(-45deg);
          }
          &:hover::after {
            opacity: 0.5;
          }
        }

        > input[type="checkbox"] {
          visibility: hidden;
          &:checked + label:after {
            opacity: 1;
          }
        }

        > div {
          font-size: 15px;
        }
      }
    }
  }

  &-formForRegions {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 25px;
    padding: 0 10px;
    &-address {
      display: flex;
      align-items: center;
      gap: 10px;
      > label {
        font-size: 15px;
        font-weight: 500;
        color: var(--black);
      }

      > input {
        width: 200px;
        height: 25px;
        background-color: var(--white);
        border: 1px solid var(--grey);
        &:focus {
          outline: none;
        }
      }
    }
  }
}

input {
  padding-left: 10px;
}

@media (max-width: 1067px) {
  .information {
    &-payer {
      &-form {
        &-item {
          flex-direction: column;
          align-items: flex-start;
        }
      }
    }
  }
}

@media (max-width: 930px) {
  .information {
    padding: 10px;
    &-formForErevan {
      flex-direction: column;
      align-items: flex-start;
      padding-left: 0;
      gap: 30px;
      &-address {
        padding: 0 10px;
      }
    }
  }
}

@media (max-width: 727px) {
  .information {
    padding: 10px;
    &-payer {
      &-form {
        flex-direction: column;
        align-items: flex-start;
        &-item {
          flex-direction: row;
        }
      }
    }
  }
}

@media (max-width: 810px) {
  .information {
    &-formForRegions {
      flex-direction: column;
      align-items: flex-start;
      &-address {
        &-adr {
          margin-right: 85px;
        }
        &-zip {
          margin-right: 19px;
        }
      }
    }
  }
}

@media (max-width: 609px) {
  .information {
    &-shipping {
      &-notes {
        > label {
          font-size: 14px;
        }
      }
    }
    &-payer {
      &-form {
        &-item {
          flex-direction: column;
          align-items: flex-start;
          > label {
            font-size: 14px;
          }
        }
      }
    }
    &-formForErevan {
      &-address {
        flex-direction: column;
        align-items: flex-start;
        > label {
          font-size: 14px;
        }
      }
      &-time {
        > div {
          padding: 0;
          border-right: 0;
        }
        flex-direction: column;
        align-items: flex-start;
        padding: 0 10px;
        gap: 15px;
        &-hours {
          gap: 10px;
        }
      }
    }

    &-formForRegions {
      &-address {
        flex-direction: column;
        align-items: flex-start;
        > label {
          font-size: 14px;
        }
      }
    }
  }
}

@media (max-width: 471px) {
  .information {
    &-title {
      font-size: 18px;
    }
    &-payer {
      &-form {
        &-item {
          flex-direction: column;
          align-items: flex-start;
        }
      }
      &-name {
        font-size: 15px;
      }
      &-form {
        &-item {
          flex-direction: column;
          > label {
            font-size: 13px;
          }
        }
      }
    }
    &-shipping {
      &-notes {
        > label {
          font-size: 13px;
        }
      }
      &-title {
        &-name {
          font-size: 15px;
        }
        &-cityName {
          &-check {
            > div {
              font-size: 13px;
            }
          }
        }
      }
    }
    &-formForErevan {
      &-address {
        flex-direction: column;
        align-items: flex-start;
        > label {
          font-size: 13px;
        }
      }
    }
    &-formForRegions {
      &-address {
        flex-direction: column;
        align-items: flex-start;
        > label {
          font-size: 13px;
        }
      }
    }
  }
}

@media (max-width: 417px) {
  .information {
    &-formForErevan {
      &-time {
        &-hours {
          > div {
            font-size: 13px;
          }
        }
      }
    }
    &-title {
      font-size: 17px;
    }
    &-payer {
      &-name {
        font-size: 14px;
      }
    }
    &-shipping {
      &-title {
        flex-direction: column;
        align-items: flex-start;
        &-name {
          border-right: 0;
        }
        &-cityName {
          padding: 0 10px;
          height: 25px;
        }
        &-name {
          font-size: 14px;
        }
      }
    }
  }
}
