@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kaushan+Script&display=swap");

*,
*::after,
*::before {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;

  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --global-max--width: 1400px;
  --black: #090606;
  --white: #f6f9fc;
  --grey: #e0e0e0;
}
