.pagination {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  padding: 0;
  flex-wrap: wrap;
  gap: 10px;
}

/* Pagination List Item */
.pagination > li {
  margin: 0 5px;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border: 1px solid #ccc;
}

/* Active Page */
.pagination > li > a {
  color: #333;
  display: block;
  padding: 12px 22px;
  font-weight: 700;
}

/* Previous and Next Buttons */
.pagination > li.previous,
.pagination > li.next {
  font-weight: bold;
}

/* Hover Effect */
.pagination > li:hover {
  background-color: #f5f5f5;
}

/* Active Page */
.pagination > li.selected {
  background-color: black;
}
.pagination > li.selected > a {
  color: white;
}

@media only screen and (max-width: 560px) {
  .pagination > li > a {
    padding: 6px 12px;
  }
}
