.contact-us {
  min-height: 443px;
  display: flex;
  align-items: center;
  flex-direction: column;
  // justify-content: center;
  // padding: 24px 14px;
  padding-bottom: 25px;

  &-title {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
  }

  &-desc {
    display: flex;
    margin-top: 50px;

    &-office {
      &-title {
        font-size: 24px;
        padding-bottom: 20px;
        position: relative;

        &::after {
          content: "";
          width: 50%;
          height: 7px;
          transition: 500ms;
          position: absolute;
          left: 0;
          bottom: 0;
          background-color: rgb(203, 203, 203);
        }

        &:hover::after {
          width: 100%;
        }
      }

      &-item {
        display: flex;
        margin-top: 30px;
        align-items: center;
        text-transform: uppercase;

        > p {
          margin-left: 10px;
        }

        > svg {
          width: 30px;
          height: 30px;
          padding: 6px;
          font-size: 10px;
          border-radius: 50%;
          border: solid 1px rgb(203, 203, 203);
          background-color: rgb(255, 255, 255);
        }
      }
    }

    &-networks {
      margin-left: 100px;

      &-title {
        font-size: 24px;
        padding-bottom: 20px;
        position: relative;

        &::after {
          content: "";
          width: 50%;
          height: 7px;
          transition: 500ms;
          position: absolute;
          left: 0;
          bottom: 0;
          background-color: rgb(203, 203, 203);
        }

        &:hover::after {
          width: 100%;
        }
      }

      &-prnt {
        margin-top: 30px;
        display: flex;
        align-items: center;

        &-item {
          color: black;

          > svg {
            font-size: 24px;
          }

          &:not(:first-child) {
            margin-left: 10px;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 615px) {
    &-desc {
      flex-direction: column;

      &-networks {
        margin-left: 0;
        margin-top: 30px;
      }
    }
  }
}
