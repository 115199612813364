.buy {
  position: relative;
  width: 30%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  &-inner {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    &-icon {
      margin-right: 7px;
      position: relative;
      &-counter {
        font-size: 12px;
        font-weight: 500;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        width: 18px;
        height: 18px;
        background-color: var(--grey);
        color: var(--black);
        position: absolute;
        top: 0;
        right: -5px;
      }
      &-icn {
        width: 28px;
        height: 28px;
        fill: var(--black);
        @media only screen and (max-width: 450px) {
          width: 28px;
          height: 28px;
        }
        @media only screen and (max-width: 380px) {
          width: 25px;
          height: 25px;
        }
      }
    }
    &-cartName {
      font-size: 17px;
      font-weight: 600;
      color: var(--black);
      padding-right: 10px;
      margin-right: 10px;
      border-right: 2px solid var(--black);
      transition: color 0.3s linear;
    }
    &-price {
      font-size: 16px;
      font-weight: 700;
      color: var(--black);
      display: flex;
      align-items: center;
      gap: 5px;

      > svg {
        width: 18px;
        height: 18px;
      }
    }
  }

  &-dialog {
    width: 350px;
    height: 200px;
    background-color: var(--white);
    border-radius: 10px;
    position: absolute;
    top: 90px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    &-inner {
      width: 100%;
      height: 100%;
      position: relative;
      overflow-y: scroll;
      overflow-x: hidden;
      padding-bottom: 50px;
      &-triIcon {
        width: 30px;
        height: 30px;
        position: absolute;
        bottom: 0px;
        left: 45%;
        z-index: 1000;
        color: var(--black);
      }
      &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
      }
      &::-webkit-scrollbar-thumb {
        background: linear-gradient(to bottom right, grey 0%, var(--grey) 100%);
        border-radius: 16px;
      }

      &-watchList {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 15px;
        position: relative;
        padding: 20px 10px;
        margin-top: 10px;
        &::after {
          content: "";
          width: 100%;
          height: 1px;
          background-color: var(--grey);
          position: absolute;
          bottom: -2px;
          left: 0;
        }
        &-image {
          width: 80px;
          min-width: 80px;
          height: 80px;
          border-radius: 10px;
          overflow: hidden;
          > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        &-info {
          display: flex;
          flex-direction: column;
          justify-content: center;
          gap: 8px;
          &-title {
            font-size: 16px;
            font-weight: 600;
            color: var(--black);
            > p {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              max-width: 230px;
            }
          }
          &-price {
            display: flex;
            align-items: center;
            gap: 2px;
            font-size: 14px;
          }
          &-view {
            color: var(--grey);
          }
        }

        &-close {
          position: absolute;
          right: 15px;
          &-icon {
            cursor: pointer;
            width: 25px;
            height: 25px;
          }
        }
      }
      &-foot {
        width: 100%;
        height: 50px;
        background-color: red;
        position: absolute;
        bottom: 0;
        left: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 15px;
        background-color: var(--white);
        border-top: 1px solid var(--grey);
        border-end-start-radius: 10px;
        border-end-end-radius: 10px;
        &-text {
          font-size: 15px;
          line-height: 120%;
          font-weight: 500;
        }
        &-price {
          font-size: 15px;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 2px;
        }
        &-button {
          font-size: 14px;
          font-weight: 500;
          background-color: red;
          padding: 10px;
          border-radius: 12px;
          border: 1px solid var(--grey);
          background-color: var(--white);
          text-decoration: none;
          color: var(--black);
          cursor: pointer;
          transition: all 0.3s linear;
          &:hover {
            background-color: var(--grey);
            border: 1px solid var(--black);
          }
        }
      }
    }
  }

  @media (max-width: 1000px) {
    .buy {
      width: 20px;
      &-inner {
        &-cartName {
          display: none;
        }
        &-price {
          display: none;
        }
      }
    }
  }
  @media only screen and (max-width: 540px) {
    &-dialog {
      width: 280px;
      top: 102px;
    }
  }

  @media only screen and (max-width: 530px) {
    &-inner {
      width: 100%;
      height: 100%;
      flex-direction: column;
      justify-content: space-evenly;

      &-icon {
        &-counter {
          width: 16px;
          height: 16px;
          font-size: 8px;
          top: -4px;
          right: -10px;
        }

        &-icn7 {
          width: 27px;
          height: 27px;
        }
      }

      &-cartName {
        margin: 0;
        padding: 0;
        text-align: center;
        font-size: 13px;
        border-right: 0px solid var(--black);
        border-bottom: 2px solid var(--black);
      }

      &-price {
        font-size: 15px;

        > svg {
          width: 14px;
          height: 14px;
        }
      }
    }

    &-dialog {
      left: -12px;
      top: 90px;
    }
  }

  @media only screen and (max-width: 450px) {
    &-inner {
      width: 100%;
      height: 100%;
      flex-direction: column;
      justify-content: space-evenly;

      &-icon {
        width: 25px;
        height: 25px;
      }
    }
  }
  @media only screen and (max-width: 500px) {
    &-dialog {
      max-width: 300px;
      left: -20px;
      &-inner-watchList-info-view {
        font-size: 11px;
      }
      &-inner-watchList-info-title > p {
        font-size: 13px;
      }
      &-inner {
        &-foot {
          &-text {
            font-size: 12px;
          }
          &-price {
            font-size: 13px;
            > svg {
              width: 12px;
              height: 12px;
            }
          }
          &-button {
            font-size: 11px;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 350px) {
    &-dialog {
      width: 300px;
      left: -40px;
      &-inner {
        &-triIcon {
          bottom: -4px;
        }
      }
    }
  }
}
