header {
  position: fixed;
  top: 0;
  z-index: 1000;
  width: 100%;
  height: 100px;
  background-color: var(--white);
}

main {
  margin-top: 100px;
  padding-bottom: 40px;
}

footer {
  width: 100%;
  min-height: 200px;
  background-color: var(--black);
}
