.slider-item {
  width: 100%;
  height: 450px;
  > a {
    width: 100%;
    height: 100%;
  }
}

.slider-item img {
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 1001px) {
  .slider-item {
    height: 300px;
  }
}

@media only screen and (max-width: 763px) {
  .slider-item {
    height: 250px;
  }
}

@media only screen and (max-width: 650px) {
  .slider-item {
    height: 220px;
  }
}

@media only screen and (max-width: 560px) {
  .slider-item {
    height: 190px;
  }
}

@media only screen and (max-width: 480px) {
  .slider-item {
    height: 170px;
  }
}

@media only screen and (max-width: 450px) {
  .slider-item {
    height: 150px;
  }
}

@media only screen and (max-width: 390px) {
  .slider-item {
    height: 120px;
  }
}
