.delivery {
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  &-inner {
    width: 100%;
    padding: 40px 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 20px;
    &-item {
      width: 100%;
      padding: 0 70px;
      &-title {
        width: 280px;
        font-size: 16px;
        font-weight: 600;
        color: var(--black);
        border-bottom: 3px solid var(--grey);
        padding: 5px 0;
        margin-bottom: 10px;
      }

      &-text {
        width: 500px;
        line-height: 150%;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .delivery-inner {
    padding: 40px;
    &-item {
      padding: 0;
    }
  }
  .delivery-inner-item-text {
    width: auto;
  }
}
