.identical {
  margin-top: 40px;
  width: 100%;
  height: 100px;
  &-title {
    font-size: 18px;
    font-weight: 400;
    text-align: center;
    margin-bottom: 5px;
    color: var(--black);
  }
  &-slider {
    width: 100%;
    height: 100%;
    border-top: 1px solid var(--black);
    border-bottom: 1px solid var(--black);
  }

  &-image {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 2px solid var(--grey);
    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }
}

.swiper-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.identical-slider-item {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  > a {
    width: 90px;
    height: 90px;
  }
}

@media only screen and (max-width: 450px) {
  .identical-slider-item {
    > a {
      height: 69px;
    }
  }
}
