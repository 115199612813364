.garuantee {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 40px;
  &-text {
    width: 50%;
    text-align: left;
    padding: 15px 10px;
  }
}

@media (max-width: 566px) {
  .garuantee {
    &-text {
      width: 70%;
    }
  }
}
