.base-single {
  display: flex;
  justify-content: center;

  &-image {
    width: 450px;
    &-featMob {
      display: none;
    }

    &-main-image {
      width: 100%;
      height: 450px;
      > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &-imgs {
      margin-top: 30px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 10px;
      &-item {
        max-width: 100px;
        height: 100px;
        > img {
          height: 100%;
          object-fit: cover;
          width: 100%;
          cursor: pointer;
        }
      }
    }
    &-videos {
      margin-top: 30px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 10px;
      &-video {
        > video {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }

  &-desc {
    width: 550px;
    transition: 250ms;
    padding-left: 50px;
    position: relative;
    &-infoM {
      align-items: center;
      justify-content: space-between;
      display: none;
      padding: 24px 0;
      border-bottom: 1px solid #d4d4d4;

      :not(:first-child) {
        margin-top: 10px;
      }

      &-title {
        font-size: 28px;
      }

      &-code {
        color: #d4d4d4;
      }

      &-price {
        font-size: 24px;
      }

      &-deliveryInfo {
        display: flex;
        font-size: 14px;
        color: #434343;
        align-items: center !important;

        > svg {
          font-size: 24px;
        }

        > span {
          margin-left: 10px;
        }
      }
    }

    &-info {
      padding: 24px 0;
      border-bottom: 1px solid #d4d4d4;

      :not(:first-child) {
        margin-top: 10px;
      }

      &-title {
        font-size: 28px;
      }

      &-code {
        color: #d4d4d4;
      }

      &-price {
        display: flex;
        flex-direction: column;
        justify-content: center;
        &-nat {
          font-size: 24px;
          > svg {
            width: 20px;
            height: 20px;
          }
        }
        &-dis {
          text-decoration: line-through;
        }
      }

      &-deliveryInfo {
        display: flex;
        font-size: 14px;
        color: #434343;
        align-items: center !important;

        > svg {
          font-size: 24px;
        }

        > span {
          margin-left: 10px;
        }
      }
    }

    &-type {
      padding: 24px 0;
      border-bottom: solid 1px #d4d4d4;

      &-txt1 {
        padding: 14px;
        font-size: 22px;
        color: #d4d4d4;
        background-color: #434343;
      }
    }

    &-buy {
      display: flex;
      padding: 24px 6px;
      align-items: center;
      justify-content: space-between;
      border-bottom: solid 1px #d4d4d4;

      &-btn {
        width: 300px;
        outline: none;
        display: flex;
        padding: 18px;
        cursor: pointer;
        font-size: 18px;
        font-weight: 600;
        border-radius: 5px;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        transition: all 0.3s linear;
        color: var(--black);
        border: 1px solid var(--black);
        background-color: var(--white);

        &:hover {
          background-color: var(--grey);
        }

        > svg {
          font-size: 18px;
          margin-right: 10px;
        }
      }
    }
  }

  @media only screen and (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
    &-image {
      width: 70%;
      &-feat {
        display: none;
      }
      &-featMob {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 20px;
      }

      &-main-image {
        height: 450px;
      }
    }

    &-desc {
      width: 70%;
      padding: 0;
      &-info {
        display: none;
      }
      &-infoM {
        display: flex;
        align-items: center;
        &-price {
          flex-direction: column;
          justify-content: center;
          &-nat {
            font-size: 25px;
            > svg {
              width: 20px;
              height: 20px;
            }
          }
          &-dis {
            font-size: 16px;
            text-align: center;
            text-decoration: line-through;
          }
        }
        &-code {
          display: none;
        }
        :not(:first-child) {
          margin: 0;
        }
      }
    }
  }

  @media only screen and (max-width: 650px) {
    &-image {
      width: 100%;

      &-main-image {
        height: 530px;
      }
    }

    &-desc {
      width: 100%;
      &-infoM {
        &-title {
          font-size: 25px;
        }
        &-price {
          &-nat {
            font-size: 22px;
            > svg {
              width: 18px;
              height: 18px;
            }
          }
        }
        &-code {
          display: none;
        }
        :not(:first-child) {
          margin: 0;
        }
      }
    }
  }

  @media only screen and (max-width: 500px) {
    &-image {
      &-main-image {
        height: 400px;
      }
    }

    &-desc {
      width: 100%;

      &-infoM {
        &-title {
          font-size: 18px;
        }
        &-price {
          &-nat {
            font-size: 18px;
            > svg {
              width: 18px;
              height: 18px;
            }
          }
          &-dis {
            font-size: 14px;
            text-align: center;
            text-decoration: line-through;
          }
        }
        &-code {
          display: none;
        }
        :not(:first-child) {
          margin: 0;
        }
      }

      &-type {
        display: flex;
        align-items: center;
        flex-direction: column;

        > span {
          width: 80%;
          text-align: center;
        }
      }

      &-buy {
        flex-direction: column;

        &-btn {
          width: 80%;
          margin-top: 10px;
        }
      }
    }
  }

  // @media only screen and (max-width: 500px) {
  //   &-desc {
  //     width: 100%;
  //     &-infoM {
  //       &-title {
  //         font-size: 20px;
  //       }
  //       &-price {
  //         font-size: 20px;
  //         > svg {
  //           width: 16px;
  //           height: 16px;
  //         }
  //       }
  //     }

  //     &-info {
  //       &-deliveryInfo {
  //         > svg {
  //           font-size: 44px;
  //         }
  //       }
  //     }
  //   }
  // }

  @media only screen and (max-width: 390px) {
    .base-single {
      &-desc {
        &-infoM {
          &-title {
            font-size: 16px;
          }
          &-price {
            &-nat {
              font-size: 17px;
              > svg {
                width: 15px;
                height: 15px;
              }
            }
            &-dis {
              font-size: 14px;
              text-align: center;
              text-decoration: line-through;
              > svg {
                width: 12px;
                height: 12px;
              }
            }
          }
        }
      }
    }
    &-image {
      &-main-image {
        height: 300px;
      }
      &-imgs {
        width: 100%;
        justify-content: center;
        &-item {
          width: 100%;
        }
      }
    }
  }
}

.swiper-slide base-single-image-imgs-item swiper-slide-next {
  width: 140px;
}

.swiper-button-prev {
  color: var(--grey);
}

.swiper-button-next {
  color: var(--grey);
}
