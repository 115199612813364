.page-banner {
  width: 100%;
  position: relative;
  height: 450px;
  // @media only screen and (max-width: 615px) {
  //   &-background {
  //     height: 100%;
  //     > img {
  //       height: 100%;
  //     }
  //   }
  // }
  &-background {
    width: 100%;
    height: auto;
    background-color: var(--white);
    > img {
      width: 100%;
      height: 100%;
    }
    // filter: brightness(0.5) blur(3px);
  }
  &-inner {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    > h1 {
      font-size: 40px;
      font-weight: 500;
      font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
        Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
        sans-serif;
      text-align: center;
      margin-bottom: 5px;
      color: var(--black);
    }
    > p {
      font-size: 16px;
      color: white;
      text-align: center;
      a {
        text-decoration: none;
        font-size: 20px;
        color: white;
        margin-right: 4px;
      }
    }
  }
}

@media (max-width: 890px) {
  .page-banner {
    height: auto;
    &-background {
      height: auto;
    }
    &-inner {
      > h1 {
        font-size: 25px;
      }
      > p a {
        font-size: 17px;
      }
    }
  }
}

@media (max-width: 850px) {
  .page-banner {
    &-inner {
      > h1 {
        font-size: 40px;
      }
      > p a {
        font-size: 17px;
      }
    }
  }
}

@media (max-width: 566px) {
  .page-banner {
    &-inner {
      > h1 {
        font-size: 16px;
      }
    }

    .h-450 {
      height: 250px;
    }
  }
}

.h-450 {
  height: 450px;
}
